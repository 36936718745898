.login-page {
	padding:150px 0;
	background: url('../images/Login-Image.jpg') no-repeat top center;
	background-size: cover;
	position: fixed;
	width: 100%;
	height: 100%;
	top:0px;
	left: 0px;
	form {
		padding:50px 90px;
		background:rgba(255,255,255,0.7);
		width: 500px;
		margin:0 auto;
		display: block;
		img {
			display: block;
			width: 90%;
			margin: 0 auto 20px;
		}
		input {
			width: 100%;
			border:1px solid $lightGray;
			padding:7px;
			background:none;
			margin:7px 0;
		}
		button {
			width: 100%;
			padding: 13px;
			margin:15px 0;
		}
	}
}
