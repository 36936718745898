.notification {
	position: fixed;
	width: 100%;
	padding:30px;
	background:gray;
	color: white;
	text-align: center;
	bottom: -100px;
	left: 0px;
	@include transition-all(.6s);
	opacity: 0;
	p {
		font-size: 18px;
	}
	&.active {
		bottom: 20px;
		opacity: 1;
	}
}