.publish-footer {
	width: 100%;
    padding: 18px 31px;
    border-top: 1px solid #e7e7e7;
    background: white;
    position: absolute;
    z-index: 2;
    bottom: 0px;
    left: 0px;
    backface-visibility: hidden;
    
    .switches {
	    padding-top: 5px;
	    p {
		    cursor: pointer;
		    &.active {
			    color: $pink;
		    }
	    }
    }
    .switch {
	    position: relative;
	    top:6px;
    }
    button {
	    margin-left: 15px;
    }
    
}