/***********************\
    Mixins
\***********************/

@mixin typeface($font-name, $filename, $weight: normal, $style: normal) {
    @font-face {
        font-family: '#{$font-name}';
        src:url('fonts/#{$filename}.eot');
        src:url('fonts/#{$filename}.eot?#iefix') format('embedded-opentype'),
            url('fonts/#{$filename}.woff') format('woff'),
            url('fonts/#{$filename}.woff2') format('woff2'),
            url('fonts/#{$filename}.ttf')  format('truetype'),
            url('fonts/#{$filename}.svg#svgFontName') format('svg');
        font-weight:$weight;
        font-style:$style;
    }
}

@mixin border-radius($rad1, $rad2:"", $rad3:"", $rad4:"") {

    @if $rad2 != "" {
        border-radius: $rad1 $rad2 $rad3 $rad4;
        -webkit-border-radius: $rad1 $rad2 $rad3 $rad4;
        -moz-border-radius: $rad1 $rad2 $rad3 $rad4;
        -o-border-radius: $rad1 $rad2 $rad3 $rad4;
    } @else {
        border-radius: $rad1;
        -webkit-border-radius: $rad1;
        -moz-border-radius: $rad1;
        -o-border-radius: $rad1;
    }
}

@mixin transition-all ($seconds) {
    transition: all $seconds;
    -moz-transition: all $seconds;
    -webkit-transition: all $seconds;
    -o-transition: all $seconds;
}

@mixin transition ($attr, $seconds) {
    transition: $attr $seconds;
    -moz-transition: $attr $seconds;
    -webkit-transition: $attr $seconds;
    -o-transition: $attr $seconds;
}

@mixin box-shadow ($x, $y, $blur, $color) {
    box-shadow: $x $y $blur $color;
    -moz-box-shadow: $x $y $blur $color;
    -webkit-box-shadow: $x $y $blur $color;
    -o-box-shadow: $x $y $blur $color;
}

@mixin opacity ($value) {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$value*100})";
    filter: alpha(opacity=$value*100);
    -moz-opacity: $value;
    -khtml-opacity: $value;
    opacity: $value;
}

@mixin placeholder-text ($color) {
    ::-webkit-input-placeholder { color: $color; }
    :-moz-placeholder { color: $color; opacity: 1; }
    ::-moz-placeholder { color: $color; opacity: 1; }
    :-ms-input-placeholder { color: $color; }
}

@mixin rotate ($degree) {
    -webkit-transform: rotate($degree);
    -moz-transform: rotate($degree);
    -ms-transform: rotate($degree);
    -o-transform: rotate($degree);
    // filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

@mixin blur ($amount) {
    -webkit-filter: blur($amount);
    -moz-filter: blur($amount);
    -o-filter: blur($amount);
    -ms-filter: blur($amount);
    filter: blur($amount);
}

@mixin basic-arrow ($size, $color) {
    display: inline-block;
    width: 0;
    height: 0;
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size+($size/2) solid transparent;
    border-bottom: $size+($size/2) solid transparent;

    &.left { border-right-color: $color; border-left-width: 0; }
    &.right { border-left-color: $color; border-right-width: 0; }
    &.up { border-bottom-color: $color; border-top-width: 0; }
    &.down { border-top-color: $color; border-bottom-width: 0; }
}

@mixin clearfix(){
	&:before, &:after {
		clear:both;
		content:"";
		display: table;
	}
}
