#main {
	position: fixed;
	width: 100%;
	height: 100%;
	padding-left: 290px;
	background: $bgTan;
}

nav {
	width: 290px;
	position: fixed;
	height: 100%;
	background:$darkGray;
	left: 0px;
	top:0px;
	.head-nav {
		padding: 0 30px 55px;
		img {
			display: block;
			margin:24px auto 15px;
			max-width: 100%;;
		}
		p {
			color:  white;
			font-size: 10px;
			font-weight: 200;
			text-align: center;
			letter-spacing: 2px;
		}
	}

	li {
		a {
			display: block;
			text-transform: uppercase;
			font-weight: 600;
			padding:16px 0 16px 45px;
			font-size: 14px;
			color: white;
		}
		&.active {
			color: $darkGray;
			background:url('../images/active-tab2.png') no-repeat;

			a {
				color: $darkGray;
			}
		}
	}
	.upload {
		position: absolute;
		bottom: 30px;
		left: 0px;
		text-align: center;
		width: 290px;
		cursor: pointer;
		color:  #ffffff;
		font-size: 12px;
		font-weight: 200;
	}
}

#wrapper {
	position: relative;
	overflow: hidden;
	height: 100%;
	padding: 67px 0;
	&.paddingless {
		padding: 0;
		#view {
			padding: 0;
		}
	}
	#main-header {
		width: 100%;
		padding:30px 31px 0;
		background: white;
		position: absolute;
		z-index: 2;
		top:0px;
		left: 0px;
		backface-visibility: hidden;
		h3 {
			// font-family: FreightBigProBold;
			color:  $darkGray;
			font-size: 24px;
			font-weight: 400;
			text-align: center;
		}
	}
	#view {
		padding:35px 100px 160px;
		position: absolute;
		width: 100%;
		height: 100%;
		overflow: scroll;

		h3 {
			margin-bottom: 30px;
			text-align: center;
		}
		.questions {
			&.pure-g {
				max-width: 920px;
				margin:0 auto;
			}
			.pure-p {
			 	padding:0 15px;
			}
			select {
				background:none;
				width: 100%;
				padding:15px;
				-webkit-appearance: none;
				appearance: none;
				border-radius:0px;
				border:none;
			}
			textarea {
				max-height: 50px;
			}
		}
	}
}

.edit-block {
	background:white;
	border:1px solid $borderGray;
	border-radius: 3px;
	max-width: 890px;
	margin:0 auto 35px;
	width: 100%;

	p {
		font-size: 16px;
		font-weight: 400;
	}
	.type {
		padding:14px 13px;
		p {
			display: inline-block;
			position: relative;
			top: -8px;
		}
		i {
			display: inline-block;
			font-size: 35px;
			padding-right: 10px;
		}
	}
	.upload {
		padding:14px 13px;
		border-top: 1px solid $lightGray;
	}
	.hover {
		opacity: 0;
		color: white;
		@include transition-all(.3s);
		width: 100%;
		height: 100%;
		position: absolute;
		top:0px;
		left:0px;
		background:rgba(0,0,0,.7);
		padding:100px 200px;
		text-align: center;

		i {
			height: 40px;
			margin-bottom: 10px;
			display: block;
			&.icon-remove {
				font-size: 26px;
			}
			&.icon-replace {
				font-size: 28px;
			}
			&.icon-link {
				font-size: 43px;
			}
		}
		a:hover {
			p,i {
				color: $pink;
			}
		}
	}
	.preview-section {
		position: relative;
		&:hover {
			.hover {
				opacity: 1;
			}
		}
		textarea {
			resize: none;
			width: 100%;
			border:none;
			padding:10px;
			height: auto;
			border-top: 1px solid $borderGray;
			border-bottom: 1px solid $borderGray;
			margin-bottom: -4px;
		}
	}
	footer {
		padding:20px 30px;

		.content-info {
			p {
				color: $textGray;
			}
			span {
				display: inline-block;
				margin-bottom: 15px;
			}
			i {
				font-size: 20px !important;
				position: relative;
				top:3px;
				margin-right: 4px;
			}
		}
		.view-notes {
			color: $pink;
			text-align: right;
			i {
				display: inline-block;
				font-size: 18px;
				position: relative;
				top:2px;
			}
			p {
				display: inline-block;
				font-weight: 600;
				font-size: 14px;
				margin-top: 3px;
			}
			a {
				display: inline-block;
			}
		}
	}
	.preview-image {
		background-size: cover !important;
		height: 285px;
	}
}

.current-brides-nav {
	margin:0 auto;
	text-align: center;
	button {
		margin:11px 0;
	}
}

.cms-tabs {
	p {
		text-align: center;
		padding:15px;
		font-size: 15px;
		cursor: pointer;
		border-bottom: 2px solid $borderGray;
		@include transition-all(.3s);
		&.active {
			border-bottom: 2px solid $pink;
		}
	}
}
