/***********************\
    Modals
\***********************/
.modal {
	overflow: visible;
	top:10px;

	.modal-content {
		box-shadow: none;
		border-radius: 0px;
	}
	.modal-header {
		background:$lightGray;
		text-align: center;
		position: relative;
		padding:40px 0 30px;

		i {
			position: absolute;
			top:-40px;
			right:0px;
			font-size: 30px;
			color: white;
			z-index: 9999;
			cursor: pointer;
		}
	}
	.modal-dialog {
		margin:40px auto;
		max-width: 1100px;
		width: 100%;
	}
	.modal-footer {
		text-align: center;
		button {
			display: inline-block;
		}
	}
}

#cropImageModal .modal-content {
	background-color: rgba(0,0,0,0) !important;
	box-shadow: none;
	border: none;
}

#cropImageModal .actions {
	background: white;
	padding: 5px 10px;
	height: 36px;
	width: 375px;
	margin: 0 auto 20px;
	button {
		padding: 5px 15px;
		float: right;
		margin-left: 5px;
	}
	i {
		font-size: 16px;
		margin: 0 5px;
		position: relative;
		top: 5px;
		display: inline-block;
		cursor: pointer;
		img {
			max-height: 15px;
		}
	}
}

#cropImageModal .modal-footer {
	border: none;
	.btn {
		background: white !important;
	}
}

#cropImageModal .modal-body, {
	position: relative;
	padding: 50px 0 50px;
	background: none;
}

#cropImageModal .edit-button, {
	border: 1px solid #194c73;
	border-radius: 2px;
	background-color: #fff;
	color: #194c73;
	font-size: 16px;
	margin: 5px;
	padding: 6px 6px;
}

#cropImageModal .rotate-button, {
	min-width: 90% !important;
}

#cropImageModal .crop-wrapper, {
	position: relative;
	margin: 0 auto;
}

#cropImageModal .crop-wrapper img, {
	position: absolute;
	top: 0px;
	left: 0px;
}

#cropImageModal .crop-wrapper .crop-area,
#cropImageModal .crop-wrapper .crop-area-edit, {
	position: absolute;
	top: 0px;
	left: 0px;
	&.visible {
		outline: 2px dashed #f00;
	}
}

#cropImageModal .crop-wrapper .crop-tool, {
	position: absolute;
	text-shadow: 0px 0px 2px rgba(0,0,0,0.8);
	color: #fff;
	top: -5px;
	cursor: pointer;
}

.backdrop-icon {
	position: relative;
	top: -40px;
	left: -40px;
	background: primary2;
	height: 45px;
	width: 45px;
	overflow: visible;
	border-radius: 50%;
}

.backdrop-icon i {
	color: #ececec;
	margin: -5px 0 0 -5px;
	font-size: 56px;
	width: 56px;
	height: 56px;
}

#dressesModal {
	text-align: center;
	.modal-dialog {
		max-width: 700px;
	}
	.modal-body {
		padding: 30px 50px
	}
	#arrow {
		position: absolute;
		top: 14px;
		right: 9px;
		height: 13px;
	}
	h1 {
		text-align: center;
		font-size: 30px;
		margin-bottom: 20px;
	}
	input {
		display: block;
		margin: 10px;
		width: 270px;
		padding: 10px;
		border: 1px solid $darkGray;
	}
	.options {
		position: relative;
		margin-bottom: 40px;
		.selected-brand {
			width: 270px;
			float: right;
			border: 1px solid $darkGray;
			display: block;
			padding: 10px 0;
			cursor: pointer;
			position: relative;
			top: 7px;
		}
		ul {
			float: right;
			width: 270px;
			position: absolute;
			background: white;
			top: 60px;
			right: 0px;
			text-align: center;
			li {
				width: 100%;
				&:hover {
					background: #ddd;
				}
			}
		}
		img {
			height: 17px;
			display: inline-block;
			&#sottero {
				height: 35px;
				margin: -7px 0;
			}
			&#rebecca {
				height: 35px;
				margin: -7px 0;
			}
		}
	}
	.dresses {
		width: 100%;
		height: 600px;
		overflow: scroll;
	}
	.dress {
		padding: 10px;
		cursor: pointer;
		&:hover, &.selected {
			background: #eee;
			border: 1px solid #ddd;
		}
	}
	.brands {
		text-align: center;
		li {
			display: inline-block;
			padding: 20px 10px;
			cursor: pointer;
			&.active {
				font-weight: 700;
			}
		}
	}
	.btn {
		padding: 16px;
		width: 200px;
		margin: 0 30px;
		display: inline-block;
	}
}
