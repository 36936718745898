#bride-page {
  min-height: 100vh;
  min-width:981px;

  .fixed-subheader {
    position:fixed;
    width:calc(100% - 290px);
    top:0;
    right:0;
    padding: 6px 0px;
    background: #fff;
    z-index: 5;
    display: flex;

    h3.name {
      position: relative;
      color: $darkGray;
      font-size: 24px;
      font-weight: 400;
      letter-spacing: 1.4px;
      margin-bottom: 0px !important;
    }

    .bride-info-left {
      position: relative;
      &:before, &:after {
        width:100%;
        background: #f6f6f6;
        position:absolute;
        left:0;
        content: "";
        height:6px;
      }
      &:before {
        top:-6px;
      }
      &:after {
        bottom:-6px;
      }
    }

    .submitted {
      text-align:center;
    }
  }
  .fixed-spacing {
    padding-bottom:4em;
  }

  .bride-content {
    display: flex;
    align-items: stretch;
  }

  .bride-info-left {
    flex: 0 0 362px;
    padding: 15px 10px 20px;
    background: #f6f6f6;
    max-width:362px;
  }

  .actions {
    width:100%;
    display:flex;
    justify-content: space-evenly;
    .btn {
      letter-spacing: .7px;
      font-size:11px;
      font-family: Arial, Helvetica, sans-serif;
    }
    .loading {
      position: relative;
      display:flex;
      justify-content: center;
      align-items: center;
      .loading-text {
        font-family: Arial, Helvetica, sans-serif;
        padding-right:25px;
        font-size: 10px;
        text-transform: uppercase;
      }
    }
    .sk-circle {
      width:20px;
      height:20px;
      position: absolute;
      top: 0;
      bottom: 0;
      right:0;
      margin: auto;
    }
  }

  .bride-info {
    text-align: center;
    position: relative;

    .wrapper {
      padding: 5px;
    }
    .row {
      margin-bottom:15px;
      display:flex;
      .column {
        padding-left:10px;
        padding-right:10px;
        flex:1;
        &.checkbox {
          display:flex;
          label {
            flex: auto;
          }
          input {
            position: relative;
            top:-5px;
            flex: 1;
          }
        }
        label {
          color:#3e3e3e;
          display:block;
          text-align: left;
          font-size:10px;
          letter-spacing: .8px;
          font-weight: 700;
          margin-bottom: 5px;
          text-transform: uppercase;
        }
        input, .textarea {
          background:#fff;
          font-size:10px;
          width:100%;
          padding:5px;
          border:none;
          &[disabled]{
            cursor: not-allowed;
          }
        }
        .textarea {
          line-height: 1.2em;
          padding:10px;
          text-align: left;
          font-size:12px;
        }
        .input-wrapper {
          position:relative;
          .input-btn {
            position:absolute;
            right:10px;
            top:0;
            bottom:0;
            margin:auto;
            font-family: Arial, Helvetica, sans-serif;
            text-decoration: underline;
            font-size: 8px;
            text-transform: none;
          }
        }
      }
    }
    .bride-story {
      margin-top: 4em;
    }
    p {
      color: $darkGray;
    }
    .dress-name {
      font-size: 13px;
      text-transform: uppercase;
      letter-spacing: 2px;
      margin-bottom: 20px;
      cursor: pointer;
      img {
        max-height: 10px;
      }
    }
    .about-wedding {
      text-align: left;
      font-size: 12px;
    }
    .options {
      padding: 30px;
      width: 100%;
      .btn {
        margin: 3px;
      }
    }
  }
  .bride-photos {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    padding: 0 11px;
    background-color: white;
    .image-post {
      flex: 0 0 50%;
      padding: 0 11px;
      margin:0 0 22px;
      img {
        max-width: 100%;
        cursor: pointer;
      }
      .image-container {
        img {
          width: 100%;
        }
      }
      .no-image {
        height: 408px;
        width: 100%;
        border: 1px dashed #ccc;
        padding-top: 200px;
        cursor: pointer;
        p {
          text-align: center;
          color: #ccc;
          letter-spacing: 1px;
          font-size: 13px;
        }
      }
      .image-info {
        padding: 15px 12px 15px;
        border: 1px solid #ebebeb;
        border-top: none;
        input {
          border: none;
          position: relative;
          top: -2px;
          width: 85%;
        }
        img {
          height: 20px;
          position: relative;
          top: 3px;
          margin-right: 10px;
        }
        .caption {
          padding: 5px 0 0;
          // color: #cfcfcf;
          font-size: 12px;
          font-weight: 400;
          cursor: pointer;
          width: 100%;
        }
      }
    }
  }
}

.modal-photo {
  .modal-dialog {
    max-width: 470px;
    width: inherit;
  }
  .modal-content {
    background: transparent;
    border: 0;
  }
  .modal-body {
    padding: 0;
  }
}

#maggie-bride-photo {
  .editor-photo {
    margin: 0 auto;
    text-align: center;
    img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
    }
  }
}

.full-loading {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 200;
  background: rgba(255,255,255,0.8);
  padding-top: 220px;
}
