#maggie-brides-section {

	input[type=text] {
		border:1px solid $darkGray;
		padding: 12px 11px;
		width: 100%;
		max-width: 295px;
		display: block;
		margin: 0 auto;
		color: $darkGray;
		font-size: 12px;
		&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			font-weight: 200;
			font-style: italic;
			color:  #b6b7b7;
		}
		&::-moz-placeholder { /* Firefox 19+ */
			font-weight: 200;
			font-style: italic;
			color:  #b6b7b7;
		}
		&:-ms-input-placeholder { /* IE 10+ */
			font-weight: 200;
			font-style: italic;
			color:  #b6b7b7;
		}
		&:-moz-placeholder { /* Firefox 18- */
			font-weight: 200;
			font-style: italic;
			color:  #b6b7b7;
		}
	}

	.tabs {
		text-align: center;
		margin-bottom: 40px;
		li + li {
			position: relative;
			border-left: 1px solid $darkGray;
		}
		li {
			padding: 0px 10px;
			display: inline-block;
			color: $darkGray;
			text-transform: uppercase;
			cursor: pointer;
			font-size: 12px;
			font-weight: 200;
			&.active {
				font-weight: 700;
			}
		}
	}

	.brides-cards {
		margin:0 -50px;
		li {
			display: inline-block;
			width:225px;
			background: white;
			margin:10px;
			height: 340px;
			overflow: hidden;
		}
		.main-image {
			height: 293px;
			width: 100%;
			position: relative;
			background-size: cover !important;
			background-color: $darkGray !important;
			.hover {
				position: absolute;
				top:0px;
				left:0px;
				width: 100%;
				height: 100%;
				background-color: rgba(255, 255, 255, 0.8);
				text-align: center;
				opacity: 0;
				@include transition-all(.3s);
				.hover-item {
					display: inline-block;
					color: $darkGray;
					padding:15px;
					cursor: pointer;
					margin-top: 120px;
					opacity:.8;
					@include transition-all(.3s);
					&.view-button {
						position: absolute;
						margin-top: 0;;
						top: 0;
						right: 0;
						padding: 8px;
						img {
							margin-bottom: 2px;
						}
						p {
							font-size: 10px;
							font-weight: 700;
							text-transform: uppercase;
						}
					}
					img {
						margin-bottom: 10px;
						max-height: 20px;
					}
					p {
						font-size: 14px;
						font-weight: 700;
						text-transform: uppercase;
					}
					&:hover {
						opacity: 1;
					}
					i {
						font-size: 30px;
						position: relative;
						top:-10px;
					}
				}
			}
			&:hover .hover {
				opacity: 1;
			}
		}
		footer {
			padding:16px 17px;
			border: 1px solid $lightGray;
			border-top: none;
			height: 47px;
			p,span {
				font-size: 12px;
				font-weight: 200;
			}
		}

	}
}


#maggie-bride-page {
	padding:20px;
	background: white;
	.grayed-area {
		background: $lightGray;
		padding: 30px;
		.pure-g {
			max-width:400px;
			margin:0 auto;
			.pure-u-1-2 {
				border-bottom: 1px solid #ccc;
				padding:15px 0;
			}
		}
		.update-btn {
			margin:15px auto 40px;
			display: block;
		}
		span {
			font-size: 12px;
			opacity: .7;
			padding:12px 0 30px;
			display: block;
		}
	}
	.email {
		background:$pink;
		color: white;
		padding:8px 18px;
		border-radius: 30px;
		display: inline-block;
		margin-bottom: 10px;
	}
	.header {
		text-align: center;
		background:url('https://placehold.it/1000x400') no-repeat center;
		background-size: cover;
		color: white;

		.avatar {
			border-radius: 60px;
			border:3px solid white;
			margin-top: -20px;
		}
		h3 {
			padding:165px 0;
		}

	}
	.post {
		width: 100%;
		max-width: 650px;
		margin:0 auto;
		padding:0 60px;
		p {
			padding:60px 0;
			line-height: 1.3em;
		}
		.image-post {
			margin-bottom: 40px;
			position: relative;

			img {
				width: 100%;
			}
			.caption {
				position: absolute;
				border-top: 1px solid $darkGray;
				padding:7px 0;
				top:0px;
				right:-165px;
				width: 150px;
			}
			.icon-remove {
				position: absolute;
				top:0px;
				left:-30px;
				cursor: pointer;
			}
		}
	}
	.dress-used {
		padding:35px;
		background:$lightGray;
		@include clearfix();
		margin-bottom: 40px;

		img {
			float: left;
			margin-right: 40px;
		}
		span {
			color: $darkGray;
			display: block;
			padding: 90px 0 3px;

		}
		h4 {

		}
	}
	.social-links {
		padding:35px;
		background:$lightGray;
		margin-bottom: 40px;
		button {
			margin-right: 20px;
		}
	}
}
