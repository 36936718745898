/***********************\
    	Libs
\***********************/

@import "../libs/reset";
@import "../libs/pure";
@import "../libs/pure-responsive";
@import "../libs/bootstrap/bootstrap";
@import "../../bower_components/textAngular/dist/textAngular";

/***********************\
    	Theme
\***********************/

@import "theme/colors";
@import "theme/typography";
@import "theme/mixins";
@import "theme/animations";
@import "theme/forms";
@import "theme/buttons";
@import "theme/modals";
@import "theme/layout";
@import "theme/textEditor";

/***********************\
    Pages
\***********************/

@import "pages/dashboard";
@import "pages/login";
@import "pages/bride";

/***********************\
    Partials
\***********************/

@import "partials/footer";
@import "partials/maggie-brides";
@import "partials/notification";
