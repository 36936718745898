/***********************\
    Typography
\***********************/
@font-face {
  font-family: 'AvenirNext';
  src: url('../fonts/AvenirNext-UltraLight.eot?#iefix') format('embedded-opentype'),  url('../fonts/AvenirNext-UltraLight.woff') format('woff'), url('../fonts/AvenirNext-UltraLight.ttf')  format('truetype'), url('../fonts/AvenirNext-UltraLight.svg#AvenirNext-UltraLight') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNext';
  src: url('../fonts/AvenirNext-Regular.eot?#iefix') format('embedded-opentype'),  url('../fonts/AvenirNext-Regular.woff') format('woff'), url('../fonts/AvenirNext-Regular.ttf')  format('truetype'), url('../fonts/AvenirNext-Regular.svg#AvenirNext-Regular') format('svg');
  font-weight: 500;
  font-style:normal ;
}
@font-face {
  font-family: 'AvenirNext';
  src: url('../fonts/AvenirNext-Medium.eot?#iefix') format('embedded-opentype'),  url('../fonts/AvenirNext-Medium.woff') format('woff'), url('../fonts/AvenirNext-Medium.ttf')  format('truetype'), url('../fonts/AvenirNext-Medium.svg#AvenirNext-Medium') format('svg');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'AvenirNext';
  src: url('../fonts/AvenirNext-Bold.eot?#iefix') format('embedded-opentype'),  url('../fonts/AvenirNext-Bold.woff') format('woff'), url('../fonts/AvenirNext-Bold.ttf')  format('truetype'), url('../fonts/AvenirNext-Bold.svg#AvenirNext-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
}

html,li,body,p,span,input,textarea {
	font-family: 'AvenirNext';
	font-weight: 500;
	font-size: 14px;
}

h1,h2,h3,h4,h5 {
	font-family: "freight-big-pro", cursive;
}

h3 {
	font-size: 30px;
}


@font-face {
	font-family: 'icomoon';
	src:url('../fonts/icomoon.eot?tdefiu');
	src:url('../fonts/icomoon.eot?#iefixtdefiu') format('embedded-opentype'),
		url('../fonts/icomoon.woff2?tdefiu') format('woff2'),
		url('../fonts/icomoon.ttf?tdefiu') format('truetype'),
		url('../fonts/icomoon.woff?tdefiu') format('woff'),
		url('../fonts/icomoon.svg?tdefiu#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}


.icon-check:before {
	content: "\e60c";
}
.icon-eye:before {
	content: "\e60d";
}
.icon-character_limit:before {
	content: "\e600";
}
.icon-header_image:before {
	content: "\e601";
}
.icon-image:before {
	content: "\e602";
}
.icon-link:before {
	content: "\e603";
}
.icon-move:before {
	content: "\e604";
}
.icon-notes:before {
	content: "\e605";
}
.icon-remove:before {
	content: "\e606";
}
.icon-replace:before {
	content: "\e607";
}
.icon-slideshow:before {
	content: "\e608";
}
.icon-specs:before {
	content: "\e609";
}
.icon-text:before {
	content: "\e60a";
}
.icon-video:before {
	content: "\e60b";
}
