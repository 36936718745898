#main {
	background: white !important;
}

.row {
	width: 100%;
	@include clearfix();

	.left {
		float: left;
	}
	.right {
		float: right;
	}
}
