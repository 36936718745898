/***********************\
    Links
\***********************/
.btn {
	padding: 9px 14px;
	border:none;
	background:none;
	cursor: pointer;
	font-family: AvenirNext;
	font-size: 10px;
	font-weight: 200;
	text-transform: uppercase;
	letter-spacing: 1px;
	&.pink {
		background:$pink;
		color: white;
	}
	&.darkGray {
		background:$darkGray;
		color: white;
	}
	&.small {
		padding:9px 15px;
		height: auto;
	}
	&.outline {
		background-color: transparent;
		border: 1px solid $darkGray;
		color: $darkGray;
	}
}
