
.btn-group {
	 display: inline-block;
	 margin:4px 0;
	 
	 button, .btn {
		 border-right: 1px solid $lightGray;
		 border-radius: 0px;
		 font-family: "AvenirNext";
		 font-weight: 500;
		 background:white;
		 height: auto;
		 padding:7px 15px;
		 color:	black;
		 font-size: 16px;
		 display: inline-block !important;
		 &.active {
			 background:$lightGray;
		 }
	 }
	 
}

.ta-toolbar {
	padding:10px;;
	background:#f7f7f7;
	border-bottom: 1px solid $lightGray;
}

.ta-scroll-window {
	height: 400px !important;
	b {
		font-weight: 600 !important;
	}
	p {
		font-weight: 500 !important;
	}
}
textarea.ta-editor {
	width: 100% !important;
}