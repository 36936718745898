/***********************\
    Forms
\***********************/
.switches {
	
	p, .switch {
		display: inline-block;
		margin-right: 14px;
	}
	.switch {
		background:$lightGray;
		height: 22px;
		width: 43px;
		border-radius: 12px;
		cursor: pointer;
		position: relative;
		
		&.default-option .ball {
			left: 2px;
		}
		
		.ball {
			background:$pink;
			display: inline-block;
			width: 18px;
			height: 18px;
			border-radius: 10px;
			@include transition(all, .3s);
			position: absolute;
			top:2px;
			left:22px;
		}
	}
}